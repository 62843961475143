import React, { FC, useContext } from 'react'
import { PageProps } from 'gatsby'
import { Box, Grid, Typography } from '@mui/material'
import { Button } from 'gatsby-material-ui-components'
import LayoutBlank from '@layouts/LayoutBlank'
import Logo from '@svgs/icon.svg'
import AuthContext from '@utils/contexts/AuthContext'
import Seo from '@components/Seo'

const PageNotFound: FC<PageProps> = () => {
  const { isLoggedIn } = useContext(AuthContext)
  return (
    <LayoutBlank>
      <Seo title={'404 Page not found'} />

      <Box mb={4} textAlign="center">
        <Box height="6rem" width="6rem" mx="auto" my={4}>
          <Logo style={{ color: 'currentColor' }} />
        </Box>
        <Typography variant="h1">{'Page Not Found.'}</Typography>

        <Typography>{'Looks like there is nothing here :('}</Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item>
          {isLoggedIn ? (
            <Button
              size="large"
              variant="contained"
              color="primary"
              to="/dashboard/"
            >
              {'Return to Dashboard'}
            </Button>
          ) : (
            <Button size="large" variant="contained" color="primary" to="/">
              {'Return to Login Screen'}
            </Button>
          )}
        </Grid>
        <Grid item>
          <Button
            size="large"
            variant="text"
            onClick={() => window !== undefined && window.history.back()}
          >
            {'Go back'}
          </Button>
        </Grid>
      </Grid>
    </LayoutBlank>
  )
}

export default PageNotFound
