import React, { FC } from 'react'
import PropTypes from 'prop-types'
import { Box, Grid } from '@mui/material'
import moment from 'moment'

const LayoutBlank: FC = ({ children }) => {
  return (
    <Grid
      container
      alignItems="stretch"
      justifyContent="center"
      sx={{
        height: 'calc(100vh - var(--vh-offset, 0px))',
        backgroundColor: 'background.default'
      }}
    >
      <Grid
        item
        xs
        sx={{
          height: '100%',
          display: 'flex',
          backgroundColor: 'background'
        }}
      >
        <Box p={8} m="auto">
          {children}
        </Box>
      </Grid>
    </Grid>
  )
}

LayoutBlank.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}
LayoutBlank.defaultProps = {}

export default LayoutBlank
